import { ReactComponent as Dash } from "../../../../assets/svg/icons/dash.svg";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import useConditionsPredisbursement from "../../../../custom-hooks/useConditionsPredisbursement";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import styles from "../PreDisbursementPendingLoanApplication.module.scss";
import { Alert } from "antd";
import { Loader } from "../../../../UI/Loaders/Loaders";
import NoTableDataAvailable from "../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";
const PreDisbursementConditions = ({
  loanApplicationId,
  customerId,
}: {
  loanApplicationId: number;
  customerId: number;
}) => {
  const dispatch = useDispatch();
  const {
    data: conditionsPredisbursmentData,
    status: conditionsPredisbursmentStatus,
    error: conditionsPredisbursmentError,
  } = useConditionsPredisbursement({
    loanApplicationId,
  });

  const { handleViewDocument } = useDocumentViewer();

  const handleView = (pdf_url: string) => {
    if (pdf_url) {
      handleViewDocument(false, pdf_url);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve document",
          },
        })
      );
    }
  };

  const handleApproveAndDeferPreDisbursement = (
    id: number,
    loanApplicationId: number,
    status: number
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "approveanddeferpredisbursement",
        action: "approveanddeferpredisbursement",
        dataRecord: { id, loanApplicationId, status, customerId },
      })
    );
  };

  // const handleViewCommentsPreDisbursement = () => {
  //   dispatch(
  //     showModal({
  //       modalIdentifier: "viewcommentspredisbursement",
  //       action: "viewcommentspredisbursement",
  //     })
  //   );
  // };
  const handleFeedbackPreDisbursement = (id: number) => {
    dispatch(
      showModal({
        modalIdentifier: "addpredisbursementfeedbacks",
        action: "addpredisbursementfeedbacks",
        dataRecord: { loanApplicationId, conditionId: id },
      })
    );
  };
  return (
    <div style={{ position: "relative" }}>
      {conditionsPredisbursmentError && (
        <Alert message={conditionsPredisbursmentError} />
      )}
      {conditionsPredisbursmentStatus === "loading" ? (
        <Loader centered={true} text="Loading  Loan Conditions..." />
      ) : (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table
              className="table"
              style={{ overflow: "hidden", minHeight: "25vh" }}
            >
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Conditions</th>
                  <th>Documents Upload</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {conditionsPredisbursmentData?.data?.length > 0 ? (
                  conditionsPredisbursmentData?.data?.map((request: any) => {
                    const {
                      id,
                      condition,
                      loanApplicationConditionDocuments,
                      status,
                    } = request;
                    return (
                      <tr key={id}>
                        <td>{condition}</td>
                        <td>
                          {loanApplicationConditionDocuments ? (
                            loanApplicationConditionDocuments?.length > 0 &&
                            loanApplicationConditionDocuments?.map(
                              (conditionDocuments: any) => {
                                const { id, document_url, document_file_name } =
                                  conditionDocuments;
                                return (
                                  <ul
                                    key={id}
                                    className="  p-0 cursor-pointer "
                                    style={{ listStyle: "none" }}
                                  >
                                    <li className="">
                                      <small>
                                        {document_file_name.slice(0, 20)}
                                      </small>
                                      <small
                                        className={`${styles.styleLink} pl-1`}
                                        onClick={() => {
                                          handleView(document_url);
                                        }}
                                      >
                                        View
                                      </small>
                                    </li>
                                  </ul>
                                );
                              }
                            )
                          ) : (
                            <small>No Document has been uploaded yet</small>
                          )}
                        </td>
                        <td> {status ? status : "N/A"}</td>
                        <td className="cursor-pointer  dropleft">
                          <span
                            className=""
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{
                              backgroundColor: "none",
                            }}
                          >
                            <Dash />
                          </span>
                          <div
                            className="dropdown-menu p-3 "
                            aria-labelledby="dropdownMenuButton"
                          >
                            <p
                              className="d-flex justify-content-center"
                              onClick={() => {
                                handleApproveAndDeferPreDisbursement(
                                  id,
                                  loanApplicationId,
                                  2
                                );
                              }}
                            >
                              Approve
                            </p>
                            <p
                              className="d-flex justify-content-center"
                              onClick={() => {
                                handleApproveAndDeferPreDisbursement(
                                  id,
                                  loanApplicationId,
                                  3
                                );
                              }}
                            >
                              Defer
                            </p>

                            {/* <p
                                className="d-flex justify-content-center"
                                onClick={() =>
                                  handleViewCommentsPreDisbursement()
                                }
                              >
                                View Comments
                              </p> */}
                            <p
                              className="d-flex justify-content-center"
                              onClick={() => handleFeedbackPreDisbursement(id)}
                            >
                              Add Feedbacks
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoTableDataAvailable text={"No Condition is Available"} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreDisbursementConditions;
