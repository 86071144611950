export const ROLE_TYPES = {
  CREDIT_VIEWER: "credit_viewer",
  DEAL_SLIP_APPROVER: "dealslip_approver",
  REPAYMENT_APPROVER: "repayment_approver",
  CREDIT_ONBOARDING_APPROVER: "credit_onboarding_approver",
  CREDIT_PRODUCT_SETUP: "credit_product_setup",
  CREDIT_PRODUCT_APPROVE: "credit_product_approver",
  CREDIT_PRODUCT_VIEWER: "credit_product_viewer",
  LEGAL_VIEWER: "legal_viewer",
  INVESTMENT_PRODUCT_SETUP: "investment_product_setup",
  AGGREGATOR_ONBOARDING_VIEWER: "aggregator_onboarding_viewer",
  CUSTOMER_CREATION_INITIATOR: "customer_creation_initiator",
  USER_CREATION_INITIATOR: "user_creation_initiator",
  GENERAL_DASHBOARD: "general_dashboard",
  NOTIFICATION_SETUP: "notification_setup",
  AGGREGATOR_ADMIN: "aggregator_admin",
  FUNDER: "funder",
  DISBURSEMENT_APPROVER: "disbursement_approver",
  INVESTMENT_PRODUCT_APPROVER: "investment_product_approver",
  CUSTOMER_WALLET_INITIATOR: "customer_wallet_initiator",
  USER_CREATION_APPROVAL: "user_creation_approval",
  BUSINESS_PERFORMANCE_DASHBOARD: "business_performance_dashboard",
  BIRTHDAY_NOTIFICATION: "birthday_notification",
  AGGREGATOR_USER: "aggregator_user",
  FUNDER_USER: "funder_user",
  CUSTOMER_INVESTMENT_INITIATOR: "customer_investment_initiator",
  CUSTOMER_WALLET_VIEWER: "customer_wallet_viewer",
  GLOBAL_SETTINGS: "global_settings",
  STANDARD_LOAN_REPORT: "standard_loan_report",
  DISBURSEMENT_INITIATOR: "Disbursement_initiator",
  FUNDER_ADMIN: "funder_admin",
  CUSTOMER_INVESTMENT_APPROVER: "customer_investment_approver",
  CUSTOMER_CREATION_APPROVER: "customer_creation_approver",
  LOAN_REPAYMENT_REPORT: "loan_repayment_report",
  REPAYMENT_INITIATOR: "repayment_initiator",
  INVESTMENT_VIEWER: "investment_viewer",
  CUSTOMER_VIEWER: "customer_viewer",
  INVESTMENT_PRODUCT_VIEWER: "investment_product_viewer",
  AGGREGATOR_WALLET_DISBURSEMENT_VIEWER:
    "aggregator_wallet_disbursement_viewer",
  AGGREGATOR_WALLET_DISBURSEMENT_APPROVER:
    "aggregator_wallet_disbursement_approver",
  INCOME_INTEREST_REPORT: "income_interest_report",
  COMPANY_WALLET: "company_wallet",
  WALLET_PROVIDER: "wallet_provider",
  WALLET_SIGNATORY_APPROVER: "wallet_signatory_approver",
  AGGREGATOR: "aggregator",
  AGGREGATORS: "aggregators",
  GLOBAL_WAITLIST: "global_waitlist",
  NOTIFICATION_VIEWER: "notification_viewer",
  AGGREGATOR_VIEWER: "aggregator_viewer",
  LEGAL_ONBOARDING_APPROVER: "legal_onboarding_approver",
  FINANCE: "finance",
  AUDIT_TRAIL: "audit_trail",
  REGULATORY_REPORT: "regulatory_report_viewer",
  QUEUE_ERROR_BUCKET: "queue_error_bucket",
  INVESTMENT_TOP_UP_INITIATOR: "investment_top_up_initiator",
  INVESTMENT_TOP_UP_VIEWER: "investment_top_up_viewer",
  INVESTMENT_TOP_UP_APPROVER: "investment_top_up_approver",
  INVESTMENT_WITHDRAWAL_INITIATOR: "investment_withdrawal_initiator",
  INVESTMENT_WITHDRAWAL_VIEWER: "investment_withdrawal_viewer",
  INVESTMENT_WITHDRAWAL_APPROVER: "investment_withdrawal_approver",
  CREDIT_RM_APPROVER: "credit_rm_application_approver",
  CREDIT_APPRAISAL: "credit_appraisal",
  CREDIT_APPLICATION_APPROVER: "credit_approver",
  MCC: "mcc",
  FUNDING_INITIATOR: "funding_initiator",
  FUNDING_APPROVER: "funding_approver",
};

export const auditRoleList = [
  ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER,
  ROLE_TYPES.INVESTMENT_VIEWER,
  ROLE_TYPES.CREDIT_VIEWER,
  ROLE_TYPES.AGGREGATOR_WALLET_DISBURSEMENT_VIEWER,
  ROLE_TYPES.AUDIT_TRAIL,
  ROLE_TYPES.AGGREGATOR_VIEWER,
  ROLE_TYPES.CUSTOMER_VIEWER,
];

export const isAudit = (userRoles, auditRoles) => {
  return JSON.stringify(userRoles.sort()) === JSON.stringify(auditRoles.sort());
};
