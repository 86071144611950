import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TStatus = "Pending" | "Approved" | "Declined";

export type TPendingLoanResponse = {
  data: {
    data: {
      id: number;
      name: string;
      status: TStatus;
      status_id: number;
      ref_name: string;
      application_date: string;
      turnover_amount: number;
      turnover_currency: string;
      approval_date: string;
      repayment_source: string;
      funder_name: string;
      funder_id: number;
      generated_cam_file_path_pdf: string;
      generated_cam_file_path_docx: string;
      funder_email: string;
      relationship_manager_id: string;
      relationship_manager: string;
      industry_information: {
        id: number;
        aggregatorQuestionnaireDetailId: number;
        isActive: boolean;
        isConditional: boolean;
        isGeneral: boolean;
        isMandatory: boolean;
        questionDomain: string;
        question: string;
        selectedAnswer: {
          questionAnswerText: string;
          adminComment: string;
        };
      }[];
      business_information: {
        questions: {
          id: number;
          aggregatorQuestionnaireDetailId: number;
          isActive: boolean;
          isConditional: boolean;
          isGeneral: boolean;
          isMandatory: boolean;
          questionDomain: string;
          question: string;
          selectedAnswer: {
            questionAnswerText: string;
            adminComment: string;
          };
        }[];
      };
      financial_information: {
        id: number;
        loan_application_id: number;
        financial_type: string;
        financial_type_id: number;
        comment: string;
        created_date: string;
        documents: {
          id: number;
          target_id: number;
          loan_application_id: number;
          description: string;
          loan_application_document_type: string;
          loan_application_document_type_id: number;
          target_type_id: number;
          target_type: string;
          document: string;
          file_name: string;
          upload_path: string;
          re_uploaded: boolean;
          date_re_uploaded: string;
          date_uploaded: string;
          approval_status: string;
          approval_status_id: number;
          created_by_user_id: number;
          approved_by_user_id: number;
          approval_date: string;
        }[];
      }[];
      collateral_information: {
        id: number;
        loan_application_id: number;
        value: number;
        registered_owner: string;
        location: string;
        source: string;
        issuer: string;
        comment_on_asset: string;
        comment: string;
        collateral_type_id: number;
        collateral_type: string;
        currency: string;
        market_value: number;
        forced_sale_value: number;
        when_last_valued: string;
        bank: string;
        tenure: number;
        effective_date: string;
        maturity_date: string;
        name_of_valuer: string;
        development_and_use_of_land: string;
        available_title_document: string;
        documents: {
          id: number;
          target_id: number;
          loan_application_id: number;
          description: string;
          loan_application_document_type: string;
          loan_application_document_type_id: number;
          target_type_id: number;
          target_type: string;
          document: string;
          file_name: string;
          upload_path: string;
          re_uploaded: boolean;
          date_re_uploaded: string;
          date_uploaded: string;
          approval_status: string;
          approval_status_id: number;
          created_by_user_id: number;
          approved_by_user_id: string;
          approval_date: string;
        }[];
      }[];

      credit_business_comment: string;
      credit_governance_and_management_comment: string;
      credit_industry_comment: string;
      credit_ownership_comment: string;
      borrower_info: string;
    };
  };
};

const getPendingLoanApplicationsById = async (id: number) => {
  const data = await getData(`${ajaxEndpoints.LOAN_APPLICATION}/${id}`);

  return data;
};

export default function usePendingLoanApplicationsById(id: number) {
  return useQuery<TPendingLoanResponse>(
    [id, "getPendingLoanApplicationsById"],
    getPendingLoanApplicationsById,
    {
      refetchOnWindowFocus: false,
    }
  );
}
