import { useEffect } from "react";
import { appInsights } from "../../../../../config/appInsights";
import CustomInputField from "../../../../CustomHTMLElements/CustomInputField";
import Accordion from "../../../Components/Accordion";
import { IDirectorDetails } from "../../../Interfaces";
import { DownloadUploadedDocument } from "../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { documentApprovalStatusString } from "../../../../AggregatorOnboardingReview/util";
import { auditRoleList, isAudit } from "../../../../../helpers/roleTypes";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

interface IDirectorsProps {
  data: IDirectorDetails[];
  setActionData: React.Dispatch<React.SetStateAction<{}>>;
}

const Directors = ({ data: directors, setActionData }: IDirectorsProps) => {
  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const boardOfDirectorsDocumentType = {
    GOVERNMENT_ID: 1,
    PROOF_OF_ADDRESS: 2,
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Directors.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {directors.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Director (${_id + 1})`}
            subTitle="Click the - icon to see more information on the director"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstName!}
                  disabled
                />
                <CustomInputField
                  label="Email"
                  type="text"
                  name="email"
                  defaultValue={_item?.email!}
                  disabled
                />
                <CustomInputField
                  label="Academic & Professional Qualification"
                  type="text"
                  name="educationalQualification"
                  defaultValue={_item?.educationalQualification!}
                  disabled
                />
                <CustomInputField
                  label="Experience relevant to the role"
                  type="text"
                  name="experienceRelevantToRole"
                  defaultValue={_item?.experienceRelevantToRole!}
                  disabled
                />
                <CustomInputField
                  label="Work Experience"
                  type="text"
                  name="workExperience"
                  defaultValue={_item?.workExperience!}
                  disabled
                />

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"ID Upload"}
                    pathname={_item?.governmentIdFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.governmentIdFilePath)
                    }
                  />

                  <div className="d-flex ml-5">
                    <div className="d-flex">
                      {_item.governmentIdApprovalStatus ===
                        documentApprovalStatusString?.DECLINED && (
                        <button
                          className="btn advancly-red-btn btn-md"
                          disabled
                        >
                          Declined
                        </button>
                      )}
                      {_item.governmentIdApprovalStatus ===
                        documentApprovalStatusString?.APPROVED && (
                        <button
                          className="btn advancly-green-btn btn-md"
                          disabled
                        >
                          Approved
                        </button>
                      )}
                    </div>

                    {_item.governmentIdApprovalStatus ===
                      documentApprovalStatusString?.PENDING &&
                      !isAudit(
                        [...loggedInUser?.roles],
                        [...auditRoleList]
                      ) && (
                        <>
                          <button
                            type="button"
                            className="btn advancly-btn btn-md"
                            data-toggle="modal"
                            data-target="#boardOfDirectorsDocumentsActionModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "approve",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                },
                              })
                            }
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn advancly-off-2-btn btn-md ml-3"
                            data-toggle="modal"
                            data-target="#boardOfDirectorsDocumentsActionModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "decline",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                },
                              })
                            }
                          >
                            Decline
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"Proof of Address"}
                    pathname={_item?.proofOfAddressFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.proofOfAddressFilePath)
                    }
                  />
                </div>
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  defaultValue={_item?.lastName!}
                  disabled
                />
                <CustomInputField
                  label="Country"
                  type="text"
                  name="countryCode"
                  defaultValue={_item?.countryCode!}
                  disabled
                />
                <CustomInputField
                  label="National Identity Number"
                  type="text"
                  name="identityNumber"
                  defaultValue={_item?.identityNumber!}
                  disabled
                />

                <CustomInputField
                  label="Period on the company board"
                  type="text"
                  name="periodInCompanyBoard"
                  defaultValue={_item?.periodInCompanyBoard!}
                  disabled
                />
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {directors && directors.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit Director Information
          </h3>
        </div>
      )}
    </div>
  );
};

export default Directors;
