export enum UpradeType {
  TIER_TWO = "2",
  TIER_THREE = "3",
}

export enum BusinessTypes {
  FREELANCER = 1,
  REGISTERED_BUSINESS = 2,
  NGO = 3,
  PARTNERSHIP = 4,
  LIMITED_LIABILITY = 5,
}

export enum SourceType {
  DIGITAL_BANK = 3,
  SPARK = 1,
}

export interface IAccounts {
  clientId: number;
  accountNumber: string;
  accountName: string;
  product: string;
  status: string;
  availableBalance: number;
  lastActive: string | null;
}

export interface IBusinessOwnersInformation {
  dateOfBirth: string;
  countryCode: string;
  address: string;
  bvn: string | null;
  city: string;
  state: string;
  identityNumber: string;
  passportUrl: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  gender: string;
}

export interface IContactPerson {
  dateOfBirth: string | null;
  state: string | null;
  identificationNumber: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
}

export interface IBusinessInformation {
  account_activation_date: string;
  aggregator_id: number;
  biz_name: string;
  biz_registration_number: string;
  biz_registration_type: number;
  biz_email: string;
  biz_phone: string;
  biz_phone_dialcode: string;
  biz_Address: string;
  biz_category: string;
  business_type: string;
  business_type_id: number;
  biz_description: string;
  sector_id: number;
  country_code: string;
  computed_score: number;
  status: string | null;
  comment: string | null;
  onboarding_status: string;
  approved_declined_by: string | null;
  approved_declined_date: string | null;
  onboarding_date: string;
  first_name: string | null;
  last_name: string | null;
  onboarding_request_date: string;
  personal_email: string;
  biz_short_name: string | null;
  biz_overview: string | null;
  city: string;
  state: string;
  country: string;
  tax_identification_number: string;
}

export interface IBusinessDocumentation {
  businessDocuments: IBusinessDocument[];
  rcNumber: string;
  taxIdentificationNumber: string;
}

export interface IBusinessDocument {
  documentId: number;
  documentName: string;
  documentType: string;
  fileName: string;
  documentUrl: string;
  documentCode: string;
  status: IDocumentStatus;
}

export interface IDocumentStatus {
  id: number;
  name: string;
  description: string | null;
}

export interface IRegisteredTrustees {
  countryCode: string;
  identityNumber: string | null;
  govtIdentityType: string;
  proofOfAddressFilePath: string;
  passportUrl: string | null;
  periodInCompanyBoard: number;
  experienceRelevantToRole: string;
  educationalQualification: string;
  governmentIdFilePath: string;
  workExperience: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  gender: string;
}

export interface ISeniorManagement {
  periodInCompanyBoard: number;
  experienceRelevantToRole: string;
  educationalQualification: string;
  governmentIdFilePath: string;
  workExperience: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  gender: string;
}

export interface IDirectorDetails {
  bvn: string | null;
  governmentIdApprovalStatus: string;
  proofOfAddressApprovalStatus: string;
  countryCode: string;
  identityNumber: string;
  govtIdentityType: string;
  proofOfAddressFilePath: string;
  passportUrl: string | null;
  periodInCompanyBoard: number;
  experienceRelevantToRole: string;
  educationalQualification: string;
  governmentIdFilePath: string;
  workExperience: string;
  funderPersonId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  gender: string;
}

export interface IShareHolders {
  shareholderType: string;
  firstname: string;
  lastname: string;
  numberOfShares: number;
  currency: string;
  nationality: string | null;
  amount: number;
  percentageShareholding: number;
}

export interface IPartners {
  dateOfBirth: string;
  countryCode: string;
  address: string;
  bvn: string | null;
  city: string;
  state: string;
  identityNumber: string;
  passportUrl: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  gender: string;
}

export interface IAuditTrail {
  actionDate: string;
  status: string;
  comment: string;
  action: string;
  startedBy: string;
  completedBy: string;
}

export interface IBusinessCustomerDetail {
  accounts: IAccounts[] | null;
  seniorManagement: ISeniorManagement[] | null;
  businessOwnerInformation: IBusinessOwnersInformation;
  partnersInformation: IPartners[] | null;
  contactPerson: IContactPerson;
  businessInformation: IBusinessInformation;
  businessDocumentation: IBusinessDocumentation;
  registeredTrustees: IRegisteredTrustees[] | null;
  directorsDetails?: IDirectorDetails[] | null;
  shareHolders?: IShareHolders[] | null;
  additionalInformation: null;
  auditTrail: IAuditTrail[];
}

export interface IBusinessCustomerSearchParams {
  email?: string;
  name?: string;
  phoneNumber?: string;
  accountNumber?: string;
  rcNumber?: string;
  businessType?: string;
  startDate?: string;
  endDate?: string;
}
