import React, { Component, useState, useCallback, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import "../../Issues/IssuesModal.css";
// UI Components
import Tabs from "../../../../components/Tabs/Tabs";

import ajaxMethods from "../../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

import {
  reOrderTableData,
  displayRepaymentColumns,
  displayLoanScheduleColumns,
  reOrderRepaymentRows,
} from "./../../../../redux/actions/loans";
import AllLoansTiedToABorrower from "../Details/AllLoansTiedToABorrower";
import { accurateQuantifier } from "./../../../../helpers/accurateQuantifier";
import ApprovalTrail from "./../../../../components/ApprovalTrail/ApprovalTrail.component";
import {
  BalanceDetails,
  IdentityDetails,
  IncomeDetails,
  StatementDetails,
} from "./viewOkraDetails";
import { formatMoney } from "../../../../helpers/formatter";
import ViewRolledOverLoans from "../ViewRolledOverLoans/ViewRolledOverLoans";
import CustomerWallet from "../CustomerWallet/CustomerWallet";
import { getData } from "../../../../newApis/dotNetApiMethods";
import ViewComments from "../../DealSlip/viewComments";
import { appInsights } from "../../../../config/appInsights";
import useBatchLoans from "../../../../custom-hooks/useBatchLoans";
import Card from "../Disburse/CreditAnalysis/Card/Card";
import AccountStatistics from "../Disburse/CreditAnalysis/AccountStatistics/AccountStatistics";
import DirectDebitDetails from "./DirectDebitDetails";
import BankStatementDetails from "./BankStatementDetails";

export default class ViewRepayment extends Component {
  state = {
    scene: "form",
    formScene: "borrower_details",
    maker_checker_reference: "",
    repayment_information: [],
    borrower_identity: {
      name: "",
      address: "",
      gender: "",
      bvn: "",
    },
    borrower_bank_balance: {
      available_balance: "",
      currency: "",
      account_name: "",
      ledger_balance: "",
      last_updated: "",
    },
    borrower_statement: {
      transaction_dates: "",
      debit: "",
      desc: "",
      bank_name: "",
      account_name: "",
      customer_name: "",
      credit: "",
    },
    borrower_full_statement: [],
    borrower_income: {
      last_updated: "",
      max_number_of_overlapping_income_streams: "",
      last_year_income: "",
      number_of_income_streams: "",
      projected_yearly_income: "",
      avg_monthly_income: "",
      income_type: "",
      confidence: "",
    },
    tabs: [
      {
        name: "Profile",
        id: "borrower_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Products",
        id: "product_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "All Loans",
        id: "all_loans_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Customer Wallet",
        id: "wallet_details",
        icon: "fas fa-wallet",
        identifier: "loanInfoTab",
      },
      {
        name: "Bank Statement",
        id: "bank_statement",
        icon: "fas fa-money-check",
        identifier: "loanInfoTab",
      },
      {
        name: "Cards",
        id: "cards",
        icon: "fab fa-cc-mastercard",
        identifier: "loanInfoTab",
      },
      {
        name: "Direct Debit",
        id: "direct_debit",
        icon: "fas fa-credit-card",
        identifier: "loanInfoTab",
      },
      {
        name: "Account Statistics",
        id: "account_statistics",
        icon: "fas fa-chart-line",
        identifier: "loanInfoTab",
      },
      {
        name: "Roll Over Loans",
        id: "rolled_over_loans",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Repayment",
        id: "repayment_details",
        icon: "fas fa-hourglass-start",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan Schedule",
        id: "schedule_details",
        icon: "fas fa-calendar",
        identifier: "loanInfoTab",
      },
      {
        name: "Repayment Information",
        id: "repayment_info",
        icon: "fas fa-hourglass-start",
        identifier: "loanInfoTab",
      },
      {
        name: "Approval Trail",
        id: "approval_trail",
        icon: "fas fa-comments",
        identifier: "loanInfoTab",
      },
      {
        name: "Disbursement Gateway",
        id: "gateway_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Retry Details",
        id: "retry_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC Identity",
        id: "identity_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC  Balance",
        id: "balance_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC Statement",
        id: "statement_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC Income",
        id: "income_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Deal Slip",
        id: "deal_slip_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Batch Loan",
        id: "batch_loans",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
    repaymentSchedule: {},
    loanSchedule: {},
  };

  componentDidMount = () => {
    let customer_id = "";
    if (this.props.dataRecord.okra_details) {
      customer_id = this.props.dataRecord.okra_details[0].okra_customer_id;
    }

    this.getLoanDetails();
    this.getBorrowerIdentity(`${customer_id}`);
    this.getBorrowerIncome(`${customer_id}`);
    this.getBorrowerBalance(`${customer_id}`);
    this.getBorrowerTransactions(`${customer_id}`);
    this.getRepaymentInfo(this.props.dataRecord?.loan_ref);
  };

  getLoanDetails = () => {
    ajaxMethods
      .get(
        ajaxEndpoints.GET_LOAN_DETAILS +
          "?loan_ref=" +
          this.props.dataRecord.loan_ref
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          this.setState({
            maker_checker_reference: res.maker_checker_reference,
          });
          if (res.paid_loans) {
            const reOrderedTable = reOrderRepaymentRows(
              displayRepaymentColumns,
              res.paid_loans
            );
            this.setState({ repaymentSchedule: reOrderedTable });
          } else {
            this.setState({ repaymentSchedule: {} });
          }
          if (res.repayment_schedule) {
            const reOrderedTable = reOrderTableData(
              displayLoanScheduleColumns,
              res.repayment_schedule
            );
            this.setState({ loanSchedule: reOrderedTable });
          } else {
          }
        }
        // if (response) {
        //     const reOrderedTableData = reOrderRows(creditHistoryColumns, response.data);
        //     setTableData( reOrderedTableData );
        // } else {

        // }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js",
          },
        });
      });
  };

  getBorrowerIdentity = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_IDENTITY + queryString)
      .then((response) => {
        if (response) {
          let identity_res = response.data.data.identity;
          this.setState({
            borrower_identity: {
              ...this.state.borrower_identity,
              address: identity_res[0].address[0],
              bvn: identity_res[0].bvn,
              name: identity_res[0].fullname,
              gender: identity_res[0].gender,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js",
          },
        });
      });
  };

  getRepaymentInfo = (query) => {
    ajaxMethods
      .get(`${ajaxEndpoints.GET_ALL_REPAYMENTS}?LoanRef=${query}`)
      .then((res) => {
        if (res?.status_code === 200) {
          this.setState({ repayment_information: res?.data });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js (getRepaymentInfo)",
          },
        });
      });
  };

  getBorrowerIncome = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_INCOME + queryString)
      .then((res) => {
        if (res) {
          let response = res.data.data;
          let streams = response.income[0].streams[0];
          this.setState({
            borrower_income: {
              ...this.state.borrower_income,
              last_updated: response.income[0].last_updated,
              max_number_of_overlapping_income_streams:
                response.income[0].max_number_of_overlapping_income_streams,
              last_year_income: response.income[0].last_year_income,
              number_of_income_streams:
                response.income[0].number_of_income_streams,
              projected_yearly_income:
                response.income[0].projected_yearly_income,
              avg_monthly_income: streams.avg_monthly_income,
              income_type: streams.income_type,
              confidence: response.income[0].confidence,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js",
          },
        });
      });
  };

  getBorrowerBalance = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_BALANCE + queryString)
      .then((response) => {
        if (response) {
          let balance = response.data.data.balance[0];
          this.setState({
            borrower_bank_balance: {
              ...this.state.borrower_bank_balance,
              available_balance: balance.available_balance,
              currency: balance.currency,
              account_name: balance.customer.name,
              ledger_balance: balance.ledger_balance,
              last_updated: balance.last_updated,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js",
          },
        });
      });
  };

  getBorrowerTransactions = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_TRANSACTIONS + queryString)
      .then((response) => {
        if (response) {
          this.setState({
            borrower_full_statement: response.data.data.transaction,
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewALlLoans.js",
          },
        });
      });
  };
  printHandler = () => {};

  cancelHandler = () => {};

  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };

  render() {
    const {
      scene,
      tabs,
      formScene,
      borrower_identity,
      borrower_bank_balance,
      borrower_full_statement,
      borrower_income,
    } = this.state;

    const { dataRecord, onCloseModal } = this.props;

    return (
      <div
        className="Modal__dialog lg-modal animated slideInDown"
        role="document"
      >
        <div
          className="IssueModal Modal__content"
          style={{ overflow: "hidden" }}
        >
          <div className="Modal__body">
            <h3 className="Modal__title p-b-2">
              Borrower id:{" "}
              <span>{dataRecord?.borrower_details?.borrower_id}</span>
            </h3>
            {(() => {
              switch (scene) {
                case "form":
                  return (
                    <form>
                      <div className="row m-b-1">
                        <Tabs
                          onContentToggle={this.toggleTabContent}
                          tabs={tabs}
                        />
                      </div>
                      {(() => {
                        switch (formScene) {
                          case "borrower_details":
                            return <BorrowerDetails details={dataRecord} />;

                          case "product_details":
                            return (
                              <ProductDetails
                                details={dataRecord.product_detail}
                              />
                            );
                          case "all_loans_details":
                            return (
                              <AllLoansTiedToABorrower
                                dataRecord={dataRecord.borrower_details}
                              />
                            );
                          case "deal_slip_details":
                            return (
                              <DealSlipDetails loanId={dataRecord.loan_id} />
                            );
                          case "batch_loans":
                            return (
                              <BatchLoanDetails
                                batch_loan_number={
                                  dataRecord?.batch_loan_number
                                }
                              />
                            );
                          case "wallet_details":
                            return (
                              <CustomerWallet
                                dataRecord={dataRecord.borrower_details}
                              />
                            );
                          case "bank_statement":
                            return <BankStatementDetails dataRecord={dataRecord} />;
                          case "cards":
                            return (
                              <Card
                                dataRecord={dataRecord}
                                first_name={
                                  dataRecord?.borrower_details?.first_name
                                }
                                last_name={
                                  dataRecord?.borrower_details?.last_name
                                }
                              />
                            );
                          case "direct_debit":
                            return (
                              <DirectDebitDetails dataRecord={dataRecord} />
                            );
                          case "account_statistics":
                            return (
                              <AccountStatistics dataRecord={dataRecord} />
                            );
                          case "rolled_over_loans":
                            return (
                              <ViewRolledOverLoans dataRecord={dataRecord} />
                            );
                          case "repayment_details":
                            return (
                              <RepaymentDetails
                                details={this.state.repaymentSchedule}
                              />
                            );
                          case "schedule_details":
                            return (
                              <LoanScheduleDetails
                                details={this.state.loanSchedule}
                              />
                            );
                          case "repayment_info":
                            return (
                              <RepaymentInformation
                                detail={this.state.repayment_information}
                              />
                            );
                          case "approval_trail":
                            return (
                              <ApprovalTrail
                                trans_ref={
                                  this.props.dataRecord.maker_checker_reference
                                }
                              />
                            );
                          case "gateway_details":
                            return <LoanGatewayDetails details={dataRecord} />;
                          case "retry_details":
                            return (
                              <DisbursementRetryDetails
                                details={dataRecord.retried_disbursement}
                              />
                            );

                          case "identity_details":
                            return (
                              <IdentityDetails
                                details={{ borrower_identity }}
                              />
                            );

                          case "balance_details":
                            return (
                              <BalanceDetails
                                details={{ borrower_bank_balance }}
                              />
                            );

                          case "statement_details":
                            return (
                              <StatementDetails
                                details={borrower_full_statement}
                              />
                            );

                          case "income_details":
                            return (
                              <IncomeDetails details={{ borrower_income }} />
                            );

                          default:
                            return null;
                        }
                      })()}
                    </form>
                  );

                case "loader":
                  return (
                    <div>
                      {/* 2. login Form Loader */}
                      <div
                        id="adv-login-loader"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading" id="adv-loader-alt" />
                            <div className="center-txt webapp-ripple-text">
                              <i className="notifier fas fa-user-circle" />
                              &nbsp; Processing your Details
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "success":
                  return (
                    <div>
                      {/* 3. Login Response */}
                      <div
                        id="adv-login-response"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading success-loader">
                              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                            </div>
                            <div className="center-txt webapp-ripple-text">
                              Logged In Successfully
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "fail":
                  return (
                    <div>
                      {/* 3. Login Response */}
                      <div
                        id="adv-login-response"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading success-loader">
                              <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                            </div>
                            <div className="center-txt webapp-ripple-text">
                              Login Failed, try again later
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })()}

            <div className="Form__action-buttons">
              {/* <button type="button"
                                className="btn zoom-element btn login-btn m-0 mr-auto"
                                onClick={this.proceedHandler}>Print</button> */}
              <button
                type="button"
                className="btn zoom-element btn login-btn"
                onClick={onCloseModal}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Loan Details
const BorrowerDetails = (props) => {
  const {
    borrower_details,
    aggregator_details,
    loan_tenure,
    loan_amount,
    loan_interest_rate,
    pub_date,
    loan_status,
    funder_name,
    loan_effective_date,
    request_dob,
    request_firstname,
    request_lastname,
    request_phone,
    request_gender,
    kyc_score,
    crcScore,
    crcStatus,
    xdsScore,
    xdsStatus,
    sf_score,
    sf_status,
    disbursement_decision,
  } = props.details;
  return (
    // <div className="m-b-2">
    <React.Fragment>
      <div className="row">
        <div className="col-6">
          <label className="label-txt">Borrower Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={`${borrower_details.first_name} ${borrower_details.last_name}`}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
        <div className="col-6">
          <label className="label-txt">Aggregator Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              name="cardNumber"
              className="user__form form-control"
              value={aggregator_details.biz_name}
            />
            <i className="far fa-credit-card" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label className="label-txt">Customer Category</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              name="cardNumber"
              className="user__form form-control"
              value={borrower_details.customer_category}
            />
            <i className="far fa-credit-card" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Company Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={borrower_details.company_name || "N/A"}
            />
            <i className="far fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Email</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={borrower_details?.customer_email || "N/A"}
            />
            <i className="far fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Business Email</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={aggregator_details?.biz_email || "N/A"}
            />
            <i className="far fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">RC Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={borrower_details.rc_number || "N/A"}
            />
            <i className="far fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={kyc_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={
                request_firstname
                  ? `${request_firstname} ${request_lastname}`
                  : "Unavailable"
              }
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request DOB</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_dob ? request_dob.substring(0, 10) : "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request Gender</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_gender || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request Phone Number</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_phone || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">CRC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crcScore || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">CRC Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crcStatus || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">First Central Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xdsScore || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">First Central Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xdsStatus || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Superfluid Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Superfluid Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Consolidated Score Decision</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={disbursement_decision || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Loan Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={formatMoney(loan_amount, aggregator_details.currency)}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Tenure</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={accurateQuantifier(loan_tenure, "day")}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Loan Interest Rate(%)</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_interest_rate}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Created Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={pub_date.substring(0, 10)}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Status</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Effective Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                loan_effective_date
                  ? loan_effective_date.substring(0, 10)
                  : "Unavailable"
              }
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Funder Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={funder_name || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Deal Slip Details

const DealSlipDetails = ({ loanId: loan_id }) => {
  const [documentData, setDocumentData] = useState(null);
  const [isDownloadDealSlip, setIsDownLoadDealSlip] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState({});

  const prepareDownloadLink = (e, content, fileName) => {
    e.preventDefault();
    const appendSource = "application/pdf";

    const linkSource = `data:${appendSource};base64,${content}`;
    const documentLink = document.createElement("a");
    documentLink.download = fileName;
    documentLink.href = linkSource;
    documentLink.click();
  };

  const getDocument = async (signed_slip_upload_path) => {
    try {
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${signed_slip_upload_path}`
      );

      if (data?.status === 200) {
        setIsLoading(false);
        setIsDownLoadDealSlip(true);
        setDocumentData(data?.data);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ViewALlLoans.js",
        },
      });
      setIsDownLoadDealSlip(false);
      setIsLoading(false);
      setMessage(error?.response?.data?.message);
    }
  };

  const getLoanInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getData(
        `${ajaxEndpoints.LOAN_DEAL_SLIP}/${loan_id}`
      );

      if (response?.data?.status_code === 200) {
        setMetaData(response?.data?.data);
        const slipPath = response?.data?.data?.signed_slip_upload_path
          ? response?.data?.data?.signed_slip_upload_path
          : response?.data?.data?.admin_uploaded_slip_path;
        getDocument(slipPath);
      } else {
        setIsLoading(false);
        setMessage(response?.data?.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ViewALlLoans.js",
        },
      });
      setIsDownLoadDealSlip(false);
      setMessage(error?.response?.data?.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => getLoanInfo(), [getLoanInfo]);

  return (
    <div style={{ maxHeight: "50vh", overflowX: "hidden" }}>
      {isLoading ? (
        <div>Loading.....</div>
      ) : (
        <>
          {isDownloadDealSlip && (
            <>
              <p>
                <h4>Status:</h4> {metaData?.status}
              </p>
              <p>
                <h4>Date:</h4>
                {moment(metaData?.datetime_created).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </p>
              <p>
                {" "}
                <h4>FileName:</h4>{" "}
                {metaData?.signed_slip_filename
                  ? metaData?.signed_slip_filename
                  : metaData?.admin_uploaded_slip_filename}
              </p>
              <button
                className="btn advancly-white-btn mr-2 mb-10"
                onClick={(e) =>
                  prepareDownloadLink(
                    e,
                    documentData?.base64_string,
                    documentData?.file_name
                  )
                }
              >
                Download
              </button>

              <div className="mt-10">
                <h3 className="mb-10">Comments:</h3>
                <ViewComments comments={metaData?.comments} />
              </div>
            </>
          )}
          {message && (
            <p className="alert alert-danger mb-4 small">{message}</p>
          )}
        </>
      )}
    </div>
  );
};

// Aggregator Details
const ProductDetails = (props) => {
  const { product_name, interest_rate, product_code } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Product</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={product_name}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>

        <div className="col-xs-6">
          <label className="label-txt">Annual Interest</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={interest_rate}
            />
            <i className="fas fa-percent" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Product Code</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={product_code}
            />
            <i className="fas fa-code" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Aggregator Details
const RepaymentDetails = (props) => {
  let renderedData = null;

  if (Object.keys(props.details).length > 0) {
    renderedData = (
      <MDBDataTable
        responsive
        minimal="true"
        striped
        bordered
        hover
        data={props.details}
      />
    );
    return renderedData;
  } else {
    renderedData = <p className="text-center">No Repayment Available</p>;
  }

  return <div className="m-b-2">{renderedData}</div>;
};

export const RepaymentInformation = (props) => {
  let renderedData = null;

  const tableData = {
    columns: [
      {
        field: "aggregator_name",
        label: "Aggregator Name",
        sort: "",
      },
      {
        field: "beneficiary_account_num",
        label: "Beneficiary Account No",
        sort: "",
      },
      {
        field: "beneficiary_bank_name",
        label: "Beneficiary Bank Name",
        sort: "",
      },
      {
        field: "loan_ref",
        label: "Loan Ref",
        sort: "",
      },
      {
        field: "repayment_status",
        label: "Repayment Status",
        sort: "",
      },
      {
        field: "repayment_date",
        label: "Repayment Date",
        sort: "",
      },
    ],
    rows: props?.detail,
  };

  if (props.detail.length > 0) {
    renderedData = (
      <MDBDataTable
        responsive
        minimal="true"
        striped
        bordered
        hover
        data={tableData}
      />
    );
    return renderedData;
  } else {
    renderedData = <p className="text-center">No Repayment Information</p>;
  }

  return <div className="m-b-2">{renderedData}</div>;
};

const BatchLoanDetails = ({ batch_loan_number }) => {
  // sample batchLoanNumber: 'ADVAN2d42e6b106c7435083f4aa5e6fd72abe'

  const { data: batchLoans } = useBatchLoans({
    batchLoanNumber: batch_loan_number,
  });

  const tableData = {
    columns: [
      {
        field: "address",
        label: "Address",
        sort: "",
      },
      {
        field: "bank_account_no",
        label: "Bank Account No",
        sort: "",
      },
      {
        field: "bank_code",
        label: "Bank Code",
        sort: "",
      },
      {
        field: "borrower_type",
        label: "Borrower Type",
        sort: "",
      },
      {
        field: "bvn",
        label: "BVN",
        sort: "",
      },
      {
        field: "city",
        label: "City",
        sort: "",
      },
      {
        field: "date_of_birth",
        label: "Date Of Birth",
        sort: "",
      },
      {
        field: "email",
        label: "Email",
        sort: "",
      },
      {
        field: "first_name",
        label: "First Name",
        sort: "",
      },
      {
        field: "gender",
        label: "Gender",
        sort: "",
      },
      {
        field: "last_name",
        label: "Last Name",
        sort: "",
      },
      {
        field: "loan_amount",
        label: "Loan Amount",
        sort: "",
      },
      {
        field: "phone",
        label: "Phone",
        sort: "",
      },
      {
        field: "rc_number",
        label: "RC Number",
        sort: "",
      },
      {
        field: "state",
        label: "State",
        sort: "",
      },
    ],
    rows: batchLoans?.data?.data,
  };

  let renderedData = null;
  if (batchLoans?.data?.data.length > 0) {
    renderedData = (
      <MDBDataTable
        responsive
        minimal="true"
        striped
        bordered
        hover
        data={tableData}
      />
    );
    return renderedData;
  } else {
    renderedData = <p className="text-center">No BatchLoans Available</p>;
  }

  return <div className="m-b-2">{renderedData}</div>;
};

// loan schedule details
const LoanScheduleDetails = (props) => {
  return (
    <div className="m-b-2">
      <MDBDataTable
        responsive
        minimal="true"
        striped
        bordered
        hover
        data={props.details}
      />
    </div>
  );
};

// loan gateway details
const LoanGatewayDetails = (props) => {
  const {
    disbursement_gateway_reference_no,
    disbursement_gateway_response,
    disbursement_gateway_status_id,
    disbursement_response_date_time,
    disbursement_gateway_request,
    disbursement_reference_no,
    disbursement_channel_id,
    disbursement_request_date_time,
    disbursement_gateway_final,
  } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Gateway Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursement_gateway_reference_no || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gateway Status ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_gateway_status_id || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Disbursement Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursement_reference_no || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Channel ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_channel_id || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <label className="label-txt">Disbursement Gateway Final:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_gateway_final || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Response:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={disbursement_gateway_response || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Request:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={disbursement_gateway_request || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Response Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              disbursement_response_date_time
                ? disbursement_response_date_time.substring(0, 10)
                : "Unavailable"
            }
          />
        </div>
      </div>

      <div className="row">
        <label className="label-txt">Request Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              disbursement_request_date_time
                ? disbursement_request_date_time.substring(0, 10)
                : "Unavailable"
            }
          />
        </div>
      </div>
    </div>
  );
};

const DisbursementRetryDetails = (props) => {
  if (props.details) {
    let rows = [];
    props.details.map((detail, index) => {
      let row_data = {
        id: index + 1,
        username: detail.username,
        retry_date_time: new Date(detail.retry_date_time),
        xlenda_id: detail.xlenda_id,
      };
      rows.push(row_data);
      return rows;
    });

    let retryDetails = {
      columns: [
        { field: "id", label: "Id", sort: "asc" },
        { field: "username", label: "Username", sort: "asc" },
        { field: "retry_date_time", label: "Retry Time", sort: "asc" },
        { field: "xlenda_id", label: "Table Id", sort: "asc" },
      ],
      rows: [],
    };
    return (
      <div style={{ maxHeight: "50vh", "overflow-x": "hidden" }}>
        <MDBDataTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          data={retryDetails}
        />
      </div>
    );
  } else {
    return (
      <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" />
            Loan disbursement was not retried.
          </h4>
        </div>
      </div>
    );
  }
};
