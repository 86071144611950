import { useEffect } from "react";
import { appInsights } from "../../../../../config/appInsights";
import CustomInputField from "../../../../CustomHTMLElements/CustomInputField";
import Accordion from "../../../Components/Accordion";
import { ISeniorManagement } from "../../../Interfaces";
import { DownloadUploadedDocument } from "../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

interface ISeniorManagementProps {
  data: ISeniorManagement[];
}

const SeniorManagement = ({
  data: seniorManagement,
}: ISeniorManagementProps) => {
  const { handleViewDocument } = useDocumentViewer();

  useEffect(() => {
    appInsights.trackPageView({
      name: "SeniorManagement.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {seniorManagement.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Senior Management (${_id + 1})`}
            subTitle="Click the - icon to see more information on the senior manager"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstName!}
                  disabled
                />
                <CustomInputField
                  label="Email"
                  type="text"
                  name="email"
                  defaultValue={_item?.email!}
                  disabled
                />
                <CustomInputField
                  label="Academic & Professional Qualification"
                  type="text"
                  name="educationalQualification"
                  defaultValue={_item?.educationalQualification!}
                  disabled
                />
                <CustomInputField
                  label="Experience relevant to the role"
                  type="text"
                  name="experienceRelevantToRole"
                  defaultValue={_item?.experienceRelevantToRole!}
                  disabled
                />
                <CustomInputField
                  label="Work Experience"
                  type="text"
                  name="workExperience"
                  defaultValue={_item?.workExperience!}
                  disabled
                />

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"ID Upload"}
                    pathname={_item?.governmentIdFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.governmentIdFilePath)
                    }
                  />
                </div>
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  defaultValue={_item?.lastName!}
                  disabled
                />

                <CustomInputField
                  label="Period on the NGO board"
                  type="text"
                  name="periodInCompanyBoard"
                  defaultValue={_item?.periodInCompanyBoard!}
                  disabled
                />
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {seniorManagement && seniorManagement.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit Senior Management Information
          </h3>
        </div>
      )}
    </div>
  );
};

export default SeniorManagement;
