import React from "react";

// Router
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { hideModal } from "../redux/actions/modal";

// Modal Child Components
import AuditTrailModal from "./Modals/AuditTrail/AuditTrailModal";
import TransactionModal from "./Modals/Transactions/TransactionModal";
import EcosystemModal from "./Modals/Ecosystems/EcosystemModal";
import IssuesModal from "./Modals/Issues/IssuesModal";
import PartnerModal from "./Modals/Partners/PartnerModal";
import LoanModal from "./Modals/Loans/LoanModal";

// Custom UI Components
import { ModalSimple } from "../UI/ModalSimple/ModalSimple";

// Componenet Styles
import "./ModalSelector.css";
import RepaymentApprovalLog from "../components/BatchLoanRepayment/ApprovalTrail/ApprovalTrail";
import ViewRepayment from "./Modals/Loans/View/ViewRepayment";
import ViewRepaymentApprovalTrail from "./Modals/Loans/View/viewRepaymentsApprovalTrail";
import ProductModal from "./Modals/Products/ProductModal";
import PendingLoansModal from "./Modals/PendingLoans/PendingLoansModal";
import FailedDisbursement from "./Modals/FailedDisbursement/FailedDisbursementModal";
import ManageProductModal from "./Modals/ManageProducts/ManageProductModal";
import ManageProductPromptModal from "./Modals/ManageProducts/ManageProductPromptModal";
import NotificationsModal from "../components/Notifications/components/NotificationsModal.component";
import UserModal from "./Modals/UserModal/UserModal";
import RoleModal from "./Modals/RoleModal/RoleModal";
import FailedRepayment from "./Modals/FailedRepayment/FailedRepaymentModal";
import ManageAggregatorSettingsPromptModal from "./Modals/ManageAggregatorSettingsModal/ManageAggregatorSettingsPromptModal";
import FailedBatchDisbursement from "./Modals/FailedBatchDisbursement/FailedBatchDisbursement";
import Investors from "./Modals/Investors/Investors";
import BatchLoanRepayment from "./Modals/BatchLoanRepayment/BatchLoanRepayment";
import Request from "./Modals/Requests/Request";
import ManageGlobalSettingsPromptModal from "./Modals/ManageGlobalSettingsPromptModal/ManageGlobalSettingsPromptModal";
import ManagePendingLiquidationSettings from "./Modals/ManagePendingLiquidationSettings/ManagePendingLiquidationSettings";
import Wallets from "./Modals/Wallets/Wallets";
import FeedBack from "./Modals/FeedBack/feedBack";
import GlobalWaitlistDetails from "./Modals/GlobalWaitlist/globalWaitlistDetails";
import ApproveDeclineDealSlip from "./Modals/ApproveDeclineDealSlip/approveDeclineDealSlip";
import CommentsModal from "./Modals/DealSlip/commentsModal";
import ProductTypeModal from "./Modals/Products/ProductTypeModal";
import ManageSignatoriesModal from "./ManageSignatoriesModalAction/ManageSignatoriesModal";
import UploadedDocCommentsModal from "./Modals/Onboarding/uploadedDocCommentsModal";
import TransactionHistoryModal from "./Modals/TransactionHistory/TransactionHistory";
import ViewLoanRepaymentDocumentsModal from "./Modals/Loans/Repayments/ViewLoanRepaymentDocuments";
import DocumentViewerModal from "./Modals/DocumentViewer/DocumentViewerModal";
import { RootState } from "../redux/store";
import BoardOfDirectorsDocumentCommentsModal from "./Modals/Onboarding/boardOfDirectorsDocumentComments";
import EditLoanInformationModal from "./Modals/Loans/LoanApplication/EditLoanInformation";
import LoanUploadedDocumentsActionModal from "./Modals/Loans/LoanApplication/LoanUploadedDocumentsActionModal";
import BusinessInformationComments from "./Modals/Loans/LoanApplication/BusinessInformationComments";
import CreateIndebtednessModal from "./Modals/Loans/LoanApplication/CreateIndebtedness";
import LoanApprovalTrail from "./Modals/Loans/LoanApplication/LoanApprovalTrail";
import Condition from "./Modals/Loans/LoanApplication/Conditions";
import Convenants from "./Modals/Loans/LoanApplication/Convenants";
import LoanSpecificConditions from "./Modals/Loans/LoanApplication/LoanSpecificConditions";
import LoanSpecificConvenants from "./Modals/Loans/LoanApplication/LoanSpecificConvenants";
import IndustryReview from "./Modals/Loans/LoanApplication/IndustryReview";
import BusinessInfoReview from "./Modals/Loans/LoanApplication/BusinessInformationReview";
import OwnershipReview from "./Modals/Loans/LoanApplication/OwnershipReview";
import GovernanceReview from "./Modals/Loans/LoanApplication/GovernanceReview";
import RepaymentCapacity from "./Modals/Loans/LoanApplication/RepaymentCapacity";
import Fees from "./Modals/Loans/LoanApplication/Fees";
import MapFees from "./Modals/Loans/LoanApplication/MapFees";
import LoanApplicationDetailFees from "./Modals/Loans/LoanApplication/LoanApplicationDetailFees";
import LoanFeeLog from "./Modals/Loans/LoanApplication/LoanApplicationDetailFeeLog";
import EditLoanApplicationCurrency from "./Modals/Loans/LoanApplication/LoanApplicationCurrency";
import TransferApplicationComments from "./Modals/Loans/LoanApplication/TransferApplicationComments";
import AddFeedbackComment from "./Modals/Loans/LoanApplication/AddFeedbackComment";
import ViewFeedbackComment from "./Modals/Loans/LoanApplication/ViewFeedbackComment";
import ApproveDeclineLoanApplication from "./Modals/Loans/LoanApplication/ApproveDeclineLoanApplication";
import CAMHistory from "./Modals/Loans/LoanApplication/CAMHistory/CAMHistory";
import CamDocumentHistory from "./Modals/OfferLetter/CamDocumentHistory/CamDocumentHistoryModal";
import AddDOCAndFileType from "./Modals/OfferLetter/AddDOCAndFileType/AddDOCAndFileType";
import UploadOfferDocuments from "./Modals/OfferLetter/UploadOfferDocuments/UploadOfferDocuments";
import CustomerOfferLetterSlaViewer from "./Modals/DocumentViewer/CustomerOfferLetterSlaViewer";
import ApproveRejectOfferSla from "./Modals/OfferLetter/ApproveRejectOfferSla/ApproveRejectOfferSla";
import PreDisbursementViewComments from "./Modals/Loans/Disburse/CreditAnalysis/PreDisbursementModal/PreDisbursementViewComments";
import PreDisbursementApproveOrDeferModal from "./Modals/Loans/Disburse/CreditAnalysis/PreDisbursementModal/PreDisbursementApproveOrDeferModal";
import PreDisbursementCompleteModal from "./Modals/Loans/Disburse/CreditAnalysis/PreDisbursementModal/PreDisbursementCompleteModal";
import PredisbursementAddFeedbacks from "./Modals/Loans/Disburse/CreditAnalysis/PreDisbursementModal/PredisbursementAddFeedbacks";
import DeleteOfferLetter from "../components/PrepareOfferLetter/DeleteOfferLetter";
import DeclinedOfferLetterHistory from "./Modals/OfferLetter/DeclinedOfferLetterHistory/DeclinedOfferLetterHistory";
import AggregatorEmbedProductModal from "./Modals/Products/AggregatorEmbedProductModal";
import FundingWalletDetails from "./Modals/CreditLineModals/FundingWalletDetails";
import FundingRequest from "./Modals/CreditLineModals/FundRequest";
import ApproveDeclineFundingRequest from "./Modals/CreditLineModals/ApproveDeclineFundingRequest";
import FundWithdrawal from "./Modals/CreditLineModals/FundWithdrawal";
import FundingRequestRepaymentSchedule from "./Modals/CreditLineModals/FundingRequestRepaymentSchedule";
import FundingRequestApprovalStatus from "./Modals/CreditLineModals/FundingRequestApprovalStatus";
import BvnAndNinInformationModal from "./Modals/DigitalBankModals/BvnAndNinInformation/bvnAndNinInformation";
import AdvancedCustomersSearchModal from "./Modals/DigitalBankModals/AdvancedCustomersSearchModal";

const ModalSelector = (props: any) => {
  const { showModal, modalProperties } = props;
  const { dataRecord, modalIdentifier } = modalProperties;
  const closeModal = () => {
    props.hideModal();
  };

  return (
    <div>
      {showModal && (
        <ModalSimple showModal={showModal} onCloseModal={closeModal}>
          {(() => {
            switch (modalIdentifier.replace(" ", "").toLowerCase()) {
              case "loanrepaymentapprovaltrail":
                return <RepaymentApprovalLog dataRecord={dataRecord} />;

              case "transaction":
                return (
                  <TransactionModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "ecosystem":
                return (
                  <EcosystemModal
                    dataId={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "issues":
                return (
                  <IssuesModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "audittrail":
                return (
                  <AuditTrailModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "loantransactionhistory":
                return (
                  <TransactionHistoryModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "createuser":
                return (
                  <UserModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "createrole":
                return (
                  <RoleModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "partner":
                return (
                  <PartnerModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "loan":
                return (
                  <LoanModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "manageproduct":
                return (
                  <ManageProductModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "manageproductprompt":
                return (
                  <ManageProductPromptModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "notifications":
                return (
                  <NotificationsModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "repayments":
                return (
                  <ViewRepayment
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "manageaggregatorsettingsprompt":
                return (
                  <ManageAggregatorSettingsPromptModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "manageglobalsettingsprompt":
                return (
                  <ManageGlobalSettingsPromptModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "managependingliquidationsettingsprompt":
                return (
                  <ManagePendingLiquidationSettings
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "managesignatoriesettings":
                return (
                  <ManageSignatoriesModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "aggregator":
                return (
                  <AggregatorEmbedProductModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "loanaggregatorproducttype":
                return (
                  <ProductTypeModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "dealslipapprovereject":
                return (
                  <ApproveDeclineDealSlip
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "product":
                return (
                  <ProductModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "pendingloan":
                return (
                  <PendingLoansModal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              // failed disbursement modal
              case "faileddisbursement":
                return (
                  <FailedDisbursement
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              // failed repayment modal
              case "failedrepayment":
                return (
                  <FailedRepayment
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              // failed batch dibursement modal
              case "failedbatchdisbursement":
                return (
                  <FailedBatchDisbursement
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              // failed batch dibursement modal
              case "investors":
                return (
                  <Investors
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              //batch loan repayment
              case "batchloanrepayment":
                return (
                  <BatchLoanRepayment
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              //batch loan repayment
              case "requests":
                return (
                  <Request
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              //wallet
              case "wallet":
                return (
                  <Wallets
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "repaymentapprovaltrail":
                return (
                  <ViewRepaymentApprovalTrail
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "feedback":
                return (
                  <FeedBack dataRecord={dataRecord} onCloseModal={closeModal} />
                );

              case "globalwaitlist":
                return (
                  <GlobalWaitlistDetails
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              // predisbursement modal

              case "approveanddeferpredisbursement":
                return (
                  <PreDisbursementApproveOrDeferModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "completepredisbursement":
                return (
                  <PreDisbursementCompleteModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "viewcommentspredisbursement":
                return <PreDisbursementViewComments />;
              case "addpredisbursementfeedbacks":
                return (
                  <PredisbursementAddFeedbacks
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "dealslipcomments":
                return (
                  <CommentsModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "uploadeddoccomments":
                return (
                  <UploadedDocCommentsModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "loanuploadeddoccomments":
                return (
                  <LoanUploadedDocumentsActionModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "viewrepaymentdocuments":
                return (
                  <ViewLoanRepaymentDocumentsModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "documentviewer":
                return (
                  <DocumentViewerModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "customerofferslaviewer":
                return (
                  <CustomerOfferLetterSlaViewer
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "deleteofferletter":
                return (
                  <DeleteOfferLetter
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "boardofdirectorsdocumentcomments":
                return (
                  <BoardOfDirectorsDocumentCommentsModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );
              case "loaninformationedit":
                return (
                  <EditLoanInformationModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "loanapprovaltrail":
                return (
                  <LoanApprovalTrail
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "rmloanapplicationcomments":
                return (
                  <BusinessInformationComments
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createindebtedness":
                return (
                  <CreateIndebtednessModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createeditconditions":
                return (
                  <Condition
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createeditconvenants":
                return (
                  <Convenants
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createloanspecificconditions":
                return (
                  <LoanSpecificConditions
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createloanspecificconvenants":
                return (
                  <LoanSpecificConvenants
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "creditanalysisindutryinformationreview":
                return (
                  <IndustryReview
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "creditanalysisbusinessinforeview":
                return (
                  <BusinessInfoReview
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "creditanalysisownershipreview":
                return (
                  <OwnershipReview
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "creditanalysisgovernancereview":
                return (
                  <GovernanceReview
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "repaymentcapacity":
                return (
                  <RepaymentCapacity
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "createeditfees":
                return (
                  <Fees dataRecord={dataRecord} onCloseModal={closeModal} />
                );

              case "mapfeestoproductdetail":
                return (
                  <MapFees dataRecord={dataRecord} onCloseModal={closeModal} />
                );

              case "loanapplicationdetailfees":
                return (
                  <LoanApplicationDetailFees
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "loanapplicationdetailfeelog":
                return (
                  <LoanFeeLog
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "loanapplicationcurrencyedit":
                return (
                  <EditLoanApplicationCurrency
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "transferapplicationcomments":
                return (
                  <TransferApplicationComments
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "addfeedbackcomment":
                return (
                  <AddFeedbackComment
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "viewfeedbackcomment":
                return (
                  <ViewFeedbackComment
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "approvedeclineloanapplication":
                return (
                  <ApproveDeclineLoanApplication
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "approverejectoffersla":
                return (
                  <ApproveRejectOfferSla
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "showcamhistory":
                return (
                  <CAMHistory
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "camdocumenthistory":
                return (
                  <CamDocumentHistory
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "uploaddocandfiletype":
                return (
                  <AddDOCAndFileType
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "uploadofferdocs":
                return (
                  <UploadOfferDocuments
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );
              case "declinedofferhistory":
                return (
                  <DeclinedOfferLetterHistory
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "fundingwalletdetails":
                return (
                  <FundingWalletDetails
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "fundingrequest":
                return (
                  <FundingRequest
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "approvedeclinefundingrequest":
                return (
                  <ApproveDeclineFundingRequest
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "fundingreceiverwalletdetails":
                return (
                  <FundWithdrawal
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "fundingrequestrepaymentschedule":
                return (
                  <FundingRequestRepaymentSchedule
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "fundingrequestapprovalstatus":
                return (
                  <FundingRequestApprovalStatus
                    properties={modalProperties}
                    onCloseModal={closeModal}
                  />
                );

              case "bvnandnindetails":
                return (
                  <BvnAndNinInformationModal
                    dataRecord={dataRecord}
                    onCloseModal={closeModal}
                  />
                );

              case "advancedcustomersearch":
                return (
                  <AdvancedCustomersSearchModal
                    onCloseModal={closeModal}
                    dataRecord={dataRecord}
                  />
                );

              default:
                return null;
            }
          })()}
        </ModalSimple>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { isAuthenticated, loggedInUser } = state.authReducer;
  const { showModal, modalProperties } = state.modalReducer;
  const { systemConfig } = state.systemReducer;
  const { wallet } = state.walletReducer;

  return {
    isAuthenticated,
    loggedInUser,
    showModal,
    modalProperties,
    wallet,
    systemConfig,
  };
};

export default withRouter(
  connect(mapStateToProps, { hideModal })(ModalSelector),
);
