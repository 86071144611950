import { useState } from "react";

import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomSelectDropdown from "./../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../Pagination/Pagination.component";
import useAggregatorOnboardingStatus from "../../../custom-hooks/useAggregatorsOnboardingStatus";
import ButtonLoader, { Loader } from "./../../../UI/Loaders/Loaders";
import { AGGREGATOR_ONBOARDING_STATUS } from "./../../../helpers/aggregatorOnboardingStatus";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { getAllAggregators } from "../../../custom-hooks/useAllAggregators";
import { showModal } from "../../../redux/actions/modal";
import { SourceType } from "../../DigitalBank/Interfaces";

import styles from "./OnboardingRequests.module.scss";

export default function OnboardingRequests() {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [count, setCount] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [query, setQuery] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [source, setSource] = useState("");

  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const { data, status, error } = useAggregatorOnboardingStatus({
    Page: pageNumber,
    PageSize: size,
    onboardingStatus: `onboardingStatus=0&onboardingStatus=1&onboardingStatus=2&onboardingStatus=3&onboardingStatus=4&onboardingStatus=5${
      source && `&SourceType=${source}`
    }`,
    AggregatorId: searchValue,
    StartDate: query?.startDate,
    EndDate: query?.endDate,
  });

  const handleSearchByDate = (e) => {
    e.preventDefault();

    const diffTime =
      new Date(filterParams.endDate) - new Date(filterParams.startDate);

    if (
      diffTime < 0 ||
      (filterParams.startDate && !filterParams.endDate) ||
      (!filterParams.startDate && filterParams.endDate)
    ) {
      dispatch(
        showModal({
          modalIdentifier: "audittrail",
          dataRecord: "",
          action: "customrange",
          type: "alert",
        })
      );
    } else {
      setQuery(filterParams);
    }
  };

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  function getOptions(query) {
    return new Promise((resolve, reject) => {
      getAllAggregators({ SearchValue: query })
        .then((data) => {
          resolve(
            data.map(({ biz_name, aggregator_id }) => ({
              value: aggregator_id,
              label: biz_name,
            }))
          );
        })
        .catch(reject);
    });
  }

  const handleAggregatorChange = (record) => {
    setSearchValue(record?.value);
    setPageNumber(1);
    setSize(1000);
  };

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const handleReviewRequest = (aggregatorId) => {
    if (Number(source) === SourceType.DIGITAL_BANK) {
      return `/db/customer-onboarding/${aggregatorId}`;
    }

    return `/aggregator-onboarding/${aggregatorId}`;
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Onboarding Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading onboarding requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              onboarding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Business Name</th>
                  <th>Contact Person</th>
                  <th>Contact Email</th>
                  <th>Business Type</th>
                  <th>Source</th>
                  <th>Request Date</th>
                  <th>Initial Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request, _id) => {
                    const {
                      aggregatorId,
                      businessName,
                      modifiedDate,
                      onboardingStatus,
                      contactPerson,
                      contactEmail,
                      businessType,
                      sourceType,
                      onboardingStartDate,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{businessName}</td>
                        <td>{contactPerson}</td>
                        <td>{contactEmail}</td>
                        <td>{businessType}</td>
                        <td>{sourceType}</td>
                        <td>
                          {onboardingStartDate
                            ? moment(onboardingStartDate).format("DD-MM-YYYY")
                            : ""}
                        </td>
                        <td>
                          {onboardingStatus &&
                          AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            ? AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            : onboardingStatus}
                        </td>
                        <td>
                          <Link
                            to={() => handleReviewRequest(aggregatorId)}
                            className="color-blue"
                          >
                            Review Request
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="mb-3">
        {status === "success" && (
          <span>
            You have <span className="font-weight-bold">{count} pending</span>{" "}
            onboarding requests
          </span>
        )}
      </div>
      <div className={styles.ActionBar}>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSearchByDate}>
            <div className="d-flex flex-row flex-wrap justify-content-start">
              <div className="m-l-1 m-r-1 d-flex form-group">
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  Start:
                </span>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      startDate: e.target.value,
                    })
                  }
                  value={filterParams.startDate}
                />
              </div>

              <div className="m-l-1 m-r-1 d-flex form-group">
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  End:
                </span>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      endDate: e.target.value,
                    })
                  }
                  value={filterParams.endDate}
                />
              </div>
              <button
                className="btn advancly-btn btn-md mb-3"
                disabled={status === "loading"}
              >
                Search{status === "loading" && <ButtonLoader />}
              </button>
            </div>
          </form>
        </div>
        <div className={styles.filterContainer}>
          {Number(source) === SourceType.SPARK && (
            <div className={styles.filter}>
              <CustomSearchDropDown
                label="Select Aggregator"
                loadOptions={getOptions}
                onChange={handleAggregatorChange}
                isShowLabel={false}
                inputHeight={48}
              />
            </div>
          )}

          <div className={styles.filter}>
            <CustomSelectDropdown
              name="sourceType"
              isShowDropdownArrow
              onChange={(e) => {
                resetTable();
                setSource(e.target.value);
              }}
              value={source}
            >
              <option value="">All</option>
              <option value={SourceType.DIGITAL_BANK}>Digital Bank</option>
              <option value={SourceType.SPARK}>Spark</option>
            </CustomSelectDropdown>
          </div>
          <div>
            <CustomSelectDropdown
              onChange={(e) => {
                resetTable();
                setSize(e.target.value);
              }}
              value={size}
            >
              <option value={10000}>All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
      </div>

      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={count}
        changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
